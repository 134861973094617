<template>
	<div class="w-screen h-screen flex justify-center items-center bg-grey-lightest">
		<div class="bg-yellow-lighter py-4 px-8">
			<p class="text-yellow-darkest text-3xl font-semibold">Page Not Found</p>
			<p class="text-yellow-darker text-center text-xl">Sending you home...</p>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			setTimeout(() => {
				window.location.href = "https://www.dishformyrv.com"
			}, 5000)
		}	
	}
</script>
